/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { HashLink } from 'react-router-hash-link';

import Page from '../../components/articlePage';
import FeaturedAsset from '../../components/featuredArticle';
import ReactMarkdown from '../../components/markdown';
import Link from '../../components/link';

export default function DCFSA (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="dcfsa subpage"
      >
        <ReactMarkdown
          source={`
## Understanding the Dependent Care Flexible Spending Account
`}
        />
        <p>If you have dependents, you already know that dependent care can be expensive! A Dependent Care Flexible Spending Account (DCFSA) is a tax-free account that can be used for expenses such as child care, preschool, elder care, summer camp and more. The Department of Defense (DoD) offers this benefit to give military families more options in paying for care. The <HashLink smooth to={`${root}#Toolkit-Using-Your-DCFSA`}>resources</HashLink> on this page provide information about how this benefit works, who is eligible and when and how to sign up.</p>
        <ReactMarkdown
          source={`
Read the [Frequently Asked Questions (FAQs)](${root}/FAQ) for a comprehensive understanding of the benefit and its details.

Sign up to receive [Office of Financial Readiness eNewsletters](/eNews-subscriber)  — or watch your inbox if you’re already a subscriber — for updates about FSAs for Service members.

`}
        />
        <div className="colored-textbox light-yellow-bg">
          <div>Enroll at <Link to="https://www.fsafeds.gov/">FSAFEDS.gov</Link> during Federal Benefits Open Season or after a <Link to="https://www.fsafeds.gov/public/pdf/FSAFEDS-QLE-Quick-Reference-Guide%20FINAL-s.pdf">qualifying life event</Link> (QLE), such as a permanent change of station (PCS), marriage, divorce, birth or adoption of a child. </div>
        </div>

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-FAQ-R.jpg"
            alt="Service member and son looking at each other"
            title="Frequently Asked Questions"
            href={`${root}/FAQ`}
            link="Find Answers"
          >
            <p>
            Looking for military-specific information about DCFSAs? Check out the most frequently asked questions and answers.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            title="What To Know for Service Members"
            img="/assets/img/FINRED-WebsiteImage-DCFSA-UniformedMembers-FS.jpg"
            alt="Dependent care FSA for Uniformed Service Members fact sheet"
            href="/assets/downloads/FINRED-DCFSA-UniformedMembers-FS.pdf"
            link="Explore Here"
          >
            <p>
            Service members who have eligible dependent care expenses may qualify to enroll in a pre-tax DCFSA and save money on preschool, summer camp, elder care and more.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-Eligibility-G.jpg"
            alt="Is a DCFSA Right For Your Family fact sheet"
            title="Service Member Eligibility Guide"
            href={`${root}/EligibilityGuide`}
            link="Get Started"
          >
            <p>
            Trying to determine whether a DCFSA is right for your family? This user-friendly tool can help simplify the decision-making process.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-VS.jpg"
            alt="Dependent Care Flexible Spending Account Video Series"
            title="Two-Part Video Series "
            href={`${root}/Videos`}
            link="Watch Now"
          >
            <p>
            Check out these short, informational videos to learn about the basics and benefits of a Dependent Care Flexible Spending Account.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FSA-W.jpg"
            alt="FSA on piggy bank and OneOp logo"
            title="“FSA: A Tax-Advantaged Way to Pay for Expenses” Webinar"
            href="https://oneop.org/learn/160047/"
            link="Get the Details"
          >
            <p>
            Learn more about HCFSA and DCFSA benefits during this interactive online session.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FSAIntegrated-P.jpg"
            alt="Health and Dependent Care Flexible Spending Account comparison poster"
            title="Compare DCFSA and HCFSA With This Poster (NEW)"
            href="/assets/downloads/FINRED-FSAIntegrated-P.pdf"
            link="View Now"
          >
            <p>
            Confused about which expenses qualify for the DCFSA versus the HCFSA? Deciding if you need both? View them side by side in this poster.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
[Toolkit-Using-Your-DCFSA]()
### Toolkit: Using Your DCFSA
`}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-SpouseConsiderations-FS.jpg"
            alt="Dependent Care Flexible Spending Account for Military Spouses fact sheet"
            title="Spouse Considerations (NEW)"
            href="/assets/downloads/FINRED-DCFSAMilitarySpouses-FS.pdf"
            link="Learn More"
          >
            <p>
            MilSpouses often handle the majority of dependent care needs for their families. Here’s what they need to know about DCFSA.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSAClaims101-G.jpg"
            alt="How to File Claims Dependent Care Flexible Spending Account guide"
            href="/assets/downloads/FINRED-DCFSAClaims101-G.pdf"
            title="How to File Claims (NEW)"
            link="Get Started"
          >
            <p>
            Learn how to file a claim. You can request reimbursement or arrange for a provider to be paid directly by FSAFEDS. Just make sure you provide a receipt.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-HowToUse-I.jpg"
            alt="10 Ways to Use Your Dependent Care Flexible Spending Account infographic"
            title="Ideas for How to Use Your DCFSA (NEW)"
            href="/assets/downloads/FINRED-10WaysDCFSA-I.pdf"
            link="Read Now"
          >
            <p>
            You can benefit from a DCFSA even if you don’t have small children. Use it for after school care, summer camp or overnight care. It can also make elder care more affordable.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
