/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

import Link from './link';

import selected from './utils/selectNav';

export default function BenefitsMenu({ setSite }) {
    return (
      <li id="Benefits" className={`${selected('/Benefits')} header-dropdown`} style={{ flexBasis: '18%', color: '#112e51' }}>
        <>
          <Link
            to={'/Benefits'}
            className="usa-nav__link"
          >
            Knowing Your Benefits and Entitlements
          </Link>
          <ul id="extended-nav-section-two" className="usa-nav-submenu benefits-menu">
            <li>
              <HashLink
                smooth
                to="/Benefits#Allowances-and-Benefits"
              >
                Allowances and Benefits
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Benefits#Education-Benefits"
              >
                Education Benefits
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Benefits#Flexible-Spending-Accounts"
              >
                Flexible Spending Accounts
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Benefits#Housing"
              >
                Housing
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Benefits#Additional-Information"
              >
                Additional Information
              </HashLink>
            </li>
          </ul>
        </>
      </li>
    );
}


