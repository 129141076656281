/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { Component } from 'react';
import Link from '../link';
import BaseTOC from './baseTOC';


export default (
  <BaseTOC
    title="Benefits and Entitlements"
    tocEntries={[
        { link: '/Benefits#Allowances-and-Benefits', text: 'Allowances and Benefits' },
        { link: '/Benefits#Education-Benefits', text: 'Education Benefits' },
        { link: '/Benefits#Flexible-Spending-Accounts', text: 'Flexible Spending Accounts' },
        { link: '/Benefits#Housing', text: 'Housing' },
        { link: '/Benefits#Additional-Information', text: 'Additional Information' },
    ]}
  />
);
