/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/articlePage';
import ReactMarkdown from '../../components/markdown';

export default function DCFSAVideos (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="dcfsa"
      >
        <ReactMarkdown
          source={`
## Dependent Care Flexible Spending Account Video Series
`}
        />
        <ReactMarkdown
          source={`
This short, two-part video series provides a quick but comprehensive overview of [Dependent Care Flexible Spending Accounts](/Benefits/DCFSA). Find out which expenses are DCFSA eligible and how the process works. If you have dependents or lead or support those who do, watch now to find out how eligible participants can use a DCFSA to pay for eligible expenses including preschool, summer camp, nannies, au pairs and child or adult day care.

* [DCFSA Eligible Expenses](${root}/video/DCFSAEligibleExpenses)
* [How a DCFSA Works](${root}/video/HowaDCFSAWorks)
`}
        />
      </Page>
    );
}
