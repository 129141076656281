/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import HCFSA from '../pages/hcfsa/hcfsa';
import FAQ from '../pages/hcfsa/faq';
import BenefitsTOC from '../components/tocs/benefits';
import Oops404Page from '../pages/oops-404';

// import HookUpArticle from '../components/hookUpArticle';

export default function HcfsaRoutes(props) {
    const root = props.match.path;
    const crumbs = `${props.crumbs},Understanding the Health Care Flexible Spending Account|${root}`;
    const toc = () => (props.toc || BenefitsTOC);

    return (
      <Switch>
        <Route
          exact
          path={`${root}/QRCode`}
          render={() => <Redirect to={root} />}
        />

        <Route
          exact
          path={root}
          render={(routerProps) => <HCFSA {...routerProps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/FAQ`}
          render={(routerProps) => <FAQ {...routerProps} crumbs={crumbs} toc={toc()} />}
        />

        {/* {[
            HookUpArticle(root, crumbs, BenefitsTOC, 'ServiceResources.md', 'Benefits', 'FAQ and Service Resources'),
        ]} */}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

