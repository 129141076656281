/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';

import videos from './videos.json';

export default function PostDeployment (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Post-Deployment`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Post-Deployment
It's easy to get caught up in the excitement and relief of returning
home from a deployment. However, there are many important financial
tasks and benefits to take care of post-deployment — like potential
tax breaks and extensions. Use these resources to help Coast Guard
members navigate their post-deployment finances.

### Checklists

* [Post-Deployment Counselor Checklist](/assets/downloads/USCG%20TC%20Counselor%20Checklist%20-%20Post-Deployment.pdf)
* [Post-Deployment Member Checklist](/assets/downloads/USCG%20TC%20Member%20Checklist%20-%20Post-Deployment.pdf)

### Handouts

* [Combat Zone Tax Exclusion](/assets/downloads/USCG%20TC%20Handout-Combat%20Zone%20Tax%20Exclusion.pdf)
* [Estate Planning](/assets/downloads/USCG%20TC%20Handout-Estate%20Planning.pdf)
* [Financial Warning Signs and Military Risk Factors](/assets/downloads/USCG%20TC%20Handout-Financial%20Warning%20Signs.pdf)
* [Major Purchases](/assets/downloads/USCG%20TC%20Handout-Major%20Purchases.pdf)
* [Military Consumer Protection](/assets/downloads/USCG%20TC%20Handout-Military%20Consumer%20Protection.pdf)
* [Paying Off Student Loans](/assets/downloads/USCG%20TC%20Handout-Paying%20off%20Student%20Loans.pdf)
* [Servicemembers Civil Relief Act](/assets/downloads/USCG%20TC%20Handout-Servicemembers%20Civil%20Relief%20Act.pdf)
* [Sources of Help for Military Consumers](/assets/downloads/USCG%20TC%20Handout-Sources%20of%20Help%20for%20Military%20Consumers.pdf)
* [Spending Plan Worksheet](/assets/downloads/USCG%20TC%20Handout-Spending%20Plan%20Worksheet.pdf)
* [Survivor Benefits Overview](/assets/downloads/USCG%20TC%20Handout-Survivor%20Benefits%20Overview.pdf)
* [Thrift Savings Plan](/assets/downloads/USCG%20TC%20Handout-Thrift%20Savings%20Plan.pdf)
* [TRICARE Overview](/assets/downloads/USCG%20TC%20Handout-TRICARE%20Overview.pdf)
* [Understanding Credit](/assets/downloads/USCG%20TC%20Handout-Understanding%20Credit.pdf)

### PowerPoint Slides, Instructor Guide and CFS Reference Manual

* [PowerPoint Slides](/assets/downloads/TC%2012B%20Post-Deployment%20Presentation%20CG%206-8-21.pptx)
* [Instructor Guide](/assets/downloads/TC%2012B%20Post-Deployment%20Instructor%20Guide%206-8-21.pdf)
* [CFS Reference Manual (Student Guide)](/assets/downloads/TC%2012B%20Post-Deployment%20CFS%20Reference%20Manual%205-27-21.pdf)

### Video Discussion Guide

* [Post-Deployment](/assets/downloads/Post-Deployment%20Video%20Discussion%20Guide-5.pdf)

### Videos
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          {
              videos.filter(v => v.category === props.category && v.order !== 0).sort((a, b) => a.order - b.order).map(v => (
                <li>
                  <Link to={`${root}/video/${v.resourceLabel}`}>{v.text}</Link>
                </li>
              ))
          }
        </ul>
      </Page>
    );
}


