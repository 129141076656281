/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';

import Page from '../components/page';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';
import AboutTOC from '../components/tocs/about';

export default function About(props) {
    return (

      <Page
        crumbs="About Us"
        toc={AboutTOC}
        {...props}
      >
        <ReactMarkdown
          source={`
# About Us

The Office of Financial Readiness, in collaboration with the services, federal partners and non-federal entities, provides programs, policy, education, advocacy and program oversight required for service members and families to achieve personal financial readiness in support of mission readiness.

On this page, you'll find answers to frequently asked questions, or FAQ, about everything from the importance of financial readiness to how to access no-cost counseling services to where to find additional information when you need it. We invite you to read through this page and then explore the rest of the site for more financial tips and resources.

[Financial-Readiness-FAQ]()
## Financial Readiness FAQ

**What is financial readiness?**

Financial readiness is defined as "the state in which successful
management of personal financial responsibilities supports service
members' ability to perform their wartime duties." This means that, with
more confidence in their personal finances, service members can better
focus on their missions — and having current, accurate information to
make educated, responsible financial decisions helps service members
become financially ready.

**Why is financial readiness training required?**

In accordance with 10 U.S.C. § 992, military departments are required to
provide financial-literacy training at personal and professional
training points throughout service members' careers. They receive
recurring financial-literacy training in order to possess the knowledge
and skills required to manage their financial responsibilities so they
can focus on their missions.

**Why is financial readiness important?**

In the military, financial readiness can directly impact service
members' ability to successfully prepare for and complete their missions
by reducing distractions from unresolved financial issues. Service
members seeking or holding security clearances may find it more
difficult to obtain or keep their clearances if they experience
financial problems. To learn more, check out the [Road to Financial
Readiness](/assets/downloads/FINRED-RTFR-F.pdf).

[Personal-Financial-Counseling-FAQ]()
## Personal Financial Counseling FAQ

**What are personal financial managers and personal financial
counselors?**

Personal financial managers and personal financial counselors are
financial experts who provide service members and their families the
training and education they need to develop to achieve financial goals
and address financial challenges. They possess a baccalaureate degree
from an accredited college or a combination of education and experience.
Additionally, PFMs and PFCs must maintain a nationally recognized
financial counselor certification in accordance with service
implementing guidance ([Department of Defense Instruction
1342.22](https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/134222p.pdf)).
PFMs and PFCs educate and counsel service members and families on a
number of financial topics, but do not endorse or favor any commercial
supplier, product or service, nor promote the services of a specific
financial institution ([DoDI
1344.07](https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/134407p.pdf)),
and thus cannot provide advisement on specific financial decisions. This
means that PFMs and PFCs are here to educate and support, without
providing specific advice.

**How can PFMs and PFCs be a proactive resource?**

PFMs and PFCs can aid with a wide range of budgeting and money
management skills. They can help service members and their spouses
understand the fundamentals of personal financial management, the
management of debt and credit, comprehend the components of the military
retirement system, recognize the importance of developing savings and an
emergency fund, recognize misleading consumer practices, and navigate
resources to increase financial literacy and understanding of personal
financial management. Even for military families who find themselves
with little extra money to spare each month, financial education and
planning can still be important, relevant and helpful.

**Who can schedule an appointment with a PFM or PFC?**

Active-duty and reserve service members and their qualifying
dependents can schedule a no-cost appointment for financial counseling
through their nearest Military and Family Support Center. PFCs are also
available to provide support to service members and their families
virtually, regardless of location. Non-remarried surviving spouses and children of
deceased service members are eligible to receive support as well.

**When should members of the military community meet with a PFM or
PFC?**

Service members and families should meet with PFMs and PFCs as early as
possible to get started on the right path to building their financial
future. Service members can meet with a PFM or PFC anytime in their
career for assistance or support with a variety of financial topics.
This is especially important as service members reach different
milestones in their military careers — such as arriving at their first
duty station, receiving a promotion, deployment and other major life
events including marriage or the birth of a child.

[Consumer-Protection-and-Credit-FAQ]()
## Consumer Protection and Credit FAQ

**What consumer and financial protections do service members have?**

Service members have several protections available to them to help
limit the effects of predatory lending practices, unfair debt collection
practices and other consumer risks including the [Servicemembers Civil
Relief
Act](/assets/downloads/FINRED-SCRA-FS.pdf),
the [Military Lending
Act](/assets/downloads/FINRED-MLA-FS.pdf),
the [Fair Debt Collection Practices
Act](/assets/downloads/FINRED-FDCPA-FS.pdf)
and the [Fair Credit Reporting
Act](/assets/downloads/FINRED-FCRA-FS.pdf).

**How can members of the military community access their credit
report?**

Free credit reports can be requested every 12 months from the three
major credit reporting agencies: Experian, TransUnion and Equifax. Visit
[AnnualCreditReport.com](https://www.annualcreditreport.com/) to request
a free report.

[Additional-Information-and-Assistance-FAQ]()
## Additional Information and Assistance FAQ

**How can service members file for receipt of continuation pay?**

Continuation pay is a direct cash payout for service members covered
under the Blended Retirement System who have completed between eight and
12 years of service. Each individual service determines the timing and
amount of continuation pay. Each service also determines and publishes
guidance on the distribution and handling of continuation pay.

**Does the Office of Financial Readiness provide financial assistance?**

The Office of Financial Readiness' mission is to lead the department's
financial readiness efforts to support a mission-ready force through
policy, programs and advocacy. While FINRED is not a direct aid society,
each service offers options for financial relief and assistance:

-   [Army Emergency Relief](https://www.armyemergencyrelief.org/)
-   [Navy-Marine Corps Relief Society](https://www.nmcrs.org/)
-   [Air Force Aid Society](https://afas.org/)
-   [Coast Guard Mutual Assistance](https://www.cgmahq.org/)

For additional help navigating financial questions, service members
and their families can schedule a no-cost appointment with PFMs and PFCs
at their nearest installation or virtually, regardless of location.

**Where can members of the military community find more information
about financial readiness?**

The FINRED website has official information, articles, tips and tools
for helping service members and their families develop and maintain
financial readiness. For regular tips and information on military money
topics, follow FINRED on
[Facebook](https://www.facebook.com/DoDFINRED),
[Instagram](https://www.instagram.com/DoDFINRED),
[LinkedIn](https://www.linkedin.com/company/dodfinred),
[X](https://x.com/DoDFINRED) and
[YouTube](https://www.youtube.com/dodfinred). For
financial information specific to the military spouse, visit the
[MilSpouse Money Mission
website](https://www.milspousemoneymission.org/). For financial tips on the go, download Sen$e — our no-cost mobile app.

`}
          renderers={{
              link: MarkLink,
          }}
        />

      </Page>
    );
}


