/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';

import videos from './videos.json';

export default function FirstDutyStation (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},First Duty Station`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## First Duty Station
The first duty station represents the beginning of a service member’s career — and it’s also the perfect time to develop habits that promote financial readiness. Whether your Coast Guard members plan to separate after a few years or complete a 20-year career, the resources on this page can help them start on the right track.

### Checklists

* [First Duty Station Counselor Checklist](/assets/downloads/USCG%20TC%20Counselor%20Checklist%20-%20First%20Duty%20Station.pdf)
* [First Duty Station Member Checklist](/assets/downloads/USCG%20TC%20Member%20Checklist%20-%20First%20Duty%20Station.pdf)

### Handouts

* [Basic Investing](/assets/downloads/USCG%20TC%20Handout-Basic%20Investing.pdf)
* [Education Benefits and Savings](/assets/downloads/USCG%20TC%20Handout-Education%20Benefits%20&%20Savings.pdf)
* [Major Purchases](/assets/downloads/USCG%20TC%20Handout-Major%20Purchases.pdf)
* [Military Banking](/assets/downloads/USCG%20TC%20Handout-Military%20Banking.pdf)
* [Military Consumer Protection](/assets/downloads/USCG%20TC%20Handout-Military%20Consumer%20Protection.pdf)
* [Paying Off Student Loans](/assets/downloads/USCG%20TC%20Handout-Paying%20off%20Student%20Loans.pdf)
* [Servicemembers Civil Relief Act](/assets/downloads/USCG%20TC%20Handout-Servicemembers%20Civil%20Relief%20Act.pdf)
* [Sources of Help for Military Consumers](/assets/downloads/USCG%20TC%20Handout-Sources%20of%20Help%20for%20Military%20Consumers.pdf)
* [Spending Plan Worksheet](/assets/downloads/USCG%20TC%20Handout-Spending%20Plan%20Worksheet.pdf)
* [Thrift Savings Plan](/assets/downloads/USCG%20TC%20Handout-Thrift%20Savings%20Plan.pdf)
* [TRICARE Overview](/assets/downloads/USCG%20TC%20Handout-TRICARE%20Overview.pdf)
* [Understanding Credit](/assets/downloads/USCG%20TC%20Handout-Understanding%20Credit.pdf)

### PowerPoint Slides, Instructor Guide and CFS Reference Manual

* [PowerPoint Slides](/assets/downloads/TC%202%20First%20Duty%20Station%20CG%206-11-21.pptx)
* [Instructor Guide](/assets/downloads/TC%202%20First%20Duty%20Station%20Instructor%20Guide%20CG%206-15-21.pdf)
* [CFS Reference Manual (Student Guide)](/assets/downloads/TC%202%20First%20Duty%20Station%20CFS%20Reference%20Manual%20CG%206-11-21.pdf)

### Video Discussion Guide

* [First Duty Station](/assets/downloads/First%20Duty%20Station%20Video%20Discussion%20Gude-5.pdf)

### Videos
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          {
              videos.filter(v => v.category === props.category && v.order !== 0).sort((a, b) => a.order - b.order).map(v => (
                <li>
                  <Link to={`${root}/video/${v.resourceLabel}`}>{v.text}</Link>
                </li>
              ))
          }
        </ul>
      </Page>
    );
}


