/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/articlePage';
import FeaturedAsset from '../../components/featuredArticle';
import ReactMarkdown from '../../components/markdown';
import Link from '../../components/link';

export default function HCFSA (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="dcfsa subpage"
      >
        <ReactMarkdown
          source={`
## Understanding the Health Care Flexible Spending Account
`}
        />
        <ReactMarkdown
          source={`
Health Care Flexible Spending Accounts (HCFSA) are a tax-advantaged way to pay for health care expenses. HCFSAs complement TRICARE by providing access to greater tax savings while managing health care. HCFSAs cover a wide range of eligible expenses, including copays for family members, dental and orthodontic care, vision care, massage therapy, drugstore products and more.

Read the [Frequently Asked Questions (FAQs)](${root}/FAQ) for a comprehensive understanding of the benefit and its details.

Sign up to receive [Office of Financial Readiness eNewsletters](/eNews-subscriber) — or watch your inbox if you’re already a subscriber — for updates about FSAs for service members.
`}
        />
        <div className="colored-textbox light-yellow-bg">
          <div>Looking for the Dependent Care Flexible Spending Account (DCFSA)?</div>
          <div>This page highlights the health care benefit, HCFSA. If you’re interested in learning about DCFSA, visit <Link to="/Benefits/DCFSA">Understanding the Dependent Care Flexible Spending Account</Link> page.</div>
        </div>
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            title="HCFSA Service Member Fact Sheet"
            img="/assets/img/FINRED-WebsiteImage-DCFSA-ComingSoon.jpg"
            alt="Dependent care FSA for Uniformed Service Members fact sheet"
            href="/assets/downloads/FINRED-DCFSA-UniformedMembers-FS.pdf"
            link="Explore"
          >
            <p>
            Service members who have eligible medical care expenses may qualify for enrollment in an HCFSA. Learn more in this fact sheet.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-ComingSoon.jpg"
            alt="Service member and son looking at each other"
            title="HCFSA Frequently Asked Questions"
            href={`${root}/FAQ`}
            link="Find Answers"
          >
            <p>
            Looking for military-specific information about HCFSAs? Check out the most frequently asked questions and answers.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-ComingSoon.jpg"
            alt="Dependent Care Flexible Spending Account Video Series"
            title="HCFSA Eligible Expenses Fact Sheet"
            href={`${root}/Videos`}
            link="Get Started"
          >
            <p>
            Trying to determine whether a medical expense is covered under HCFSA? Start with our easy-to-understand fact sheet.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-ComingSoon.jpg"
            alt="Is a DCFSA Right For Your Family fact sheet"
            title="Spread the Word With This Poster"
            href={`${root}/EligibilityGuide`}
            link="Preview Here"
          >
            <p>
            Share the news that HCFSA is now available for service members! View the poster by clicking the button below.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-ComingSoon.jpg"
            alt="Dependent Care Flexible Spending Account webinar series"
            title="Comparing the DCFSA and HCFSA"
            href={`${root}/Webinars`}
            link="Read Now"
          >
            <p>
            Use our handy comparison tool to see which FSA is right for you, or if your family would benefit from both.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-ComingSoon.jpg"
            alt="Dependent care FSA for Military Families and Spouses fact sheet"
            href="/assets/downloads/FINRED-DCFSA-MilFamiliesSpouses-FS.pdf"
            title="How to File Claims"
            link="Get Started"
          >
            <p>
            Learn how to file a claim. You can request reimbursement or arrange for a provider to be paid directly by FSAFEDS.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FSA-W.jpg"
            alt="Dependent care FSA for Military Families and Spouses fact sheet"
            href="/assets/downloads/FINRED-DCFSA-MilFamiliesSpouses-FS.pdf"
            title="“FSA: A Tax-Advantaged Way to Pay for Expenses” Webinar"
            link="Get the Details"
          >
            <p>
            Learn more about HCFSA and DCFSA benefits during this interactive online session.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
